// Variable zum Speichern des Übergangsstatus
var transitionDone = true;

// Funktion zum Ändern des Logos
function changeLogo() {
    var logo = document.getElementById('logo');
    var logoImg1 = document.getElementById('logoImg1');
    var logoImg2 = document.getElementById('logoImg2');
    var scrollPosition = window.scrollY;

    if (logo == null) return
    if (logoImg1 == null) return
    if (logoImg2 == null) return

    if (scrollPosition > 400 && !transitionDone) {
        logo.classList.replace('logo', 'logo2');
        logoImg1.classList.add('has-opacity-0');
        logoImg2.classList.add('has-opacity-0');
        setTimeout(function () {
            if (logo == null) return
            if (logoImg1 == null) return
            if (logoImg2 == null) return

            logoImg1.classList.add('is-hidden');
            logoImg2.classList.remove('is-hidden');
            logoImg1.classList.remove('has-opacity-0');
            logoImg2.classList.remove('has-opacity-0');
        }, 200);
        transitionDone = true;
    } else if (scrollPosition <= 400 && transitionDone) {
        logo.classList.replace('logo2', 'logo');
        logoImg1.classList.add('has-opacity-0');
        logoImg2.classList.add('has-opacity-0');
        setTimeout(function () {
            if (logo == null) return
            if (logoImg1 == null) return
            if (logoImg2 == null) return

            logoImg1.classList.remove('is-hidden');
            logoImg2.classList.add('is-hidden');
            logoImg1.classList.remove('has-opacity-0');
            logoImg2.classList.remove('has-opacity-0');
        }, 200);
        transitionDone = false;
    }
}

function openPopup(): void {
    const popupOverlay = document.getElementById('popupOverlay');
    if (popupOverlay) {
        popupOverlay.classList.replace('displaynone', 'displayflex');
        document.body.style.overflow = 'hidden';
    }
}

function closePopup(): void {
    const popupOverlay = document.getElementById('popupOverlay');
    if (popupOverlay) {
        popupOverlay.classList.replace('displayflex', 'displaynone');
        document.body.style.overflow = 'auto';
    }
}



// Event-Listener für das Scroll-Event
window.addEventListener('scroll', changeLogo);

let currentFormSection = 'form_start';
let formSectionHistory = ['form_start'];

// Event-Listener für das Formular
document.addEventListener('DOMContentLoaded', function () {
    changeLogo();

    const weiterButton = document.getElementById("weiterbutton");
    const zurueckButton = document.getElementById("zurueckbutton");
    const sendButton = document.getElementById("sendbutton");
    const formSections = document.querySelectorAll(".formsection");

    if (!weiterButton || !zurueckButton) return;

    weiterButton.addEventListener("click", function () {
        if (!validateForm(currentFormSection)) return
        if (currentFormSection != 'form_start') {
            const formSectionElement = document.getElementById(currentFormSection)
            if (formSectionElement?.dataset.next) currentFormSection = formSectionElement?.dataset.next;
        } else {
            let nextFormSection = (document.querySelector('input[name="test"]:checked') as HTMLElement).dataset.next
            if (nextFormSection) currentFormSection = nextFormSection
            formSectionHistory = ['form_start'];
        }
        formSectionHistory.push(currentFormSection)
        showCurrentFormSection()
    });

    zurueckButton.addEventListener("click", function () {
        if (currentFormSection != 'form_start' && formSectionHistory.length > 1) {
            currentFormSection = formSectionHistory[formSectionHistory.length - 2]
            formSectionHistory.pop();
        } else {
            currentFormSection = 'form_start'
            alert("Sie sind bereits am Anfang.");
        }
        showCurrentFormSection()
    });

    function showCurrentFormSection() {
        formSections.forEach(formSection => {
            if (!formSection.classList.contains("displaynone")) {
                formSection.classList.add("displaynone");
            }
            if (formSection.id == currentFormSection) {
                formSection.classList.remove("displaynone");
            }
        });
        if (currentFormSection == 'form_contact') {
            weiterButton?.classList.add('displaynone');
            sendButton?.classList.remove('displaynone');
        } else {
            weiterButton?.classList.remove('displaynone');
            sendButton?.classList.add('displaynone');
        }
    }

    sendButton?.addEventListener("click", sendButtonAbschicken);

    function getInput(selector: string): string {
        const e = (document.querySelector(selector) as HTMLInputElement)
        if (e) return e.value
        return e
    }

    async function sendButtonAbschicken() {
        if (!validateForm(currentFormSection)) return
        let text = getInput('input[name="test"]:checked')
        text += "; URL? " + getInput('input[name="url"]')
        text += "; Target? " + getInput('textarea[name="description"]')
        text += "; Static? " + getInput('input[name="static"]:checked')
        text += "; Login? " + getInput('input[name="haslogin"]:checked')
        text += "; IPs? " + getInput('input[name="ip_range"]')
        text += "; How many Hosts? " + getInput('input[name="hosts"]')
        text += "; Focus? " + getInput('textarea[name="focus_areas"]')
        text += "; Excluded? " + getInput('textarea[name="excluded_parts"]')
        text += "; When? " + getInput('input[name="start_time"]')
        text += "; Report Language? " + getInput('input[name="report_language"]:checked')
        text += "; Comment: " + getInput('textarea[name="comments"]')
        const obj = {
            Name: getInput('input[name="name"]'),
            Email: getInput('input[name="email"]'),
            Company: getInput('input[name="company"]'),
            Phone: getInput('input[name="phone"]'),
            Text: text,
        }
        try {
            const res = await fetch('https://rasotec.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            })
            if (res.ok) {
                openPopup()
                return
            }
            if (res.status == 429) {
                alert("Too many requests! Try again later.");
            }
            alert("Error");
        } catch (error) {
            alert("Error");
        }
    }

    function validateForm(formSection: string): boolean {
        const formSectionElement = document.getElementById(formSection)
        if (!formSectionElement) return true

        const requiredFields = Array.from(formSectionElement.querySelectorAll('.Pflichtfeld')) as HTMLInputElement[];
        for (const field of requiredFields) {
            if (!field.value.trim()) {
                alert('Please fill out all mandatory input fields! Bitte alle Pflichtfelder ausfüllen!');
                return false;
            }
        }
        return true;
    }


    const blocks = document.querySelectorAll<HTMLElement>(".block");

    function fadeInBlocks() {
        if (window.innerWidth < 700) {
            // Entferne die Klasse 'visibleblock' und füge 'block' hinzu, um den Effekt zu deaktivieren
            blocks.forEach(block => {
                block.classList.replace('visibleblock', 'block');
            });
        } else {
            blocks.forEach(block => {
                if (isElementInViewport(block)) {
                    block.classList.replace('block', 'visibleblock');
                }
            });
        }
    }

    function isElementInViewport(el: HTMLElement): boolean {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // Direktes Auslösen der Animation für den ersten Block
    const firstBlock = document.querySelector<HTMLElement>(".firstblock");
    if (firstBlock) {
        firstBlock.classList.replace('firstblock', 'visibleblock');
    }

    window.addEventListener("scroll", fadeInBlocks);
    window.addEventListener("resize", fadeInBlocks);

    // Initiales Auslösen der Funktion, um den Zustand bei Seitenaufruf zu prüfen
    fadeInBlocks();

    const openButton = document.getElementById('openpopup');
    if (openButton) {
        openButton.addEventListener('click', openPopup);
    }

    const closeButton = document.getElementById('closepopup');
    if (closeButton) {
        closeButton.addEventListener('click', closePopup);
    }


});